<mat-drawer-container *ngIf="{ view: (view$ | async) } as asyncData" class="container-box">
  <mat-drawer-content>
    <ng-container [ngSwitch]="asyncData.view">
      <ng-container *ngSwitchCase="View.RESET_PASSWORD">
        <auth-reset-password class="content-box" [email]="email$ | async"></auth-reset-password>
      </ng-container>

      <ng-container *ngSwitchCase="View.INVALID_VERIFICATION">
        <auth-invalid-verification class="content-box"></auth-invalid-verification>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <random-background class="backgrounds" [images]="backgroundImages$ | async"></random-background>
      </ng-container>
    </ng-container>
  </mat-drawer-content>

  <mat-drawer *ngIf="!hideDrawer(asyncData.view)" opened mode="side" position="end">
    <auth-sidenav [style.width]="(isMobile$ | async) ? '100vw' : '424px'"></auth-sidenav>
  </mat-drawer>
</mat-drawer-container>
