<div class="auth-content-wrapper z-fx-col z-fx-gap-48">
  <a href="//zef.fi" target="_blank">
    <img src="assets/images/zeffi-bird.svg" width="32" alt="ZEF Logo" i18n-alt="logo image alt" />
  </a>

  <div class="auth-content-container z-fx-col z-fx-gap-16">
    <mat-icon class="zef-icon-large" color="warning">bullet_alert</mat-icon>

    <strong class="zef-text-h2" i18n>Email address verification failed</strong>

    <span class="zef-text-p1" i18n>Make sure the verification link is copied completely.</span>
  </div>
</div>
