import { Component, Input } from '@angular/core';
import { PasswordData } from '@auth/auth-passwords/auth-passwords.component';
import { AuthError } from '@auth/auth.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AuthManager } from '@shared/services/auth-manager.service';
import { AuthenticationError, SignOutWithoutRedirect } from '@shared/states/auth.actions';
import { RouterState } from '@shared/states/router.state';
import { BehaviorSubject } from 'rxjs';

enum AuthView {
  DEFAULT,
  NO_MATCH,
  ERROR,
  SUCCESS,
  PROGRESS,
}

@Component({
  selector: 'auth-reset-password',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
})
export class ResetPassword {
  public View = AuthView;

  @Input()
  public email: string = '';

  view$: BehaviorSubject<any> = new BehaviorSubject(AuthView.DEFAULT);

  constructor(
    private am: AuthManager,
    private store: Store,
  ) {}

  clearError() {
    this.view$.next(AuthView.DEFAULT);
  }

  submitPassword(pwdata: PasswordData) {
    if (!pwdata.isStrong) {
      this.store.dispatch(new AuthenticationError(AuthError.PASSWORD_TOO_WEAK));
    } else if (!pwdata.isMatched) {
      this.store.dispatch(new AuthenticationError(AuthError.PASSWORD_MISMATCH));
    } else {
      this.store.dispatch(new AuthenticationError(null));
      const password = pwdata.value;
      this.view$.next(AuthView.PROGRESS);

      const oobCode = this.store.selectSnapshot(RouterState.queryParams)?.oobCode;
      this.am.resetPassword(oobCode, password).subscribe({
        next: () => {
          this.view$.next(AuthView.SUCCESS);
        },
        error: () => {
          this.view$.next(AuthView.ERROR);
          this.store.dispatch(new AuthenticationError(AuthError.PASSWORD_RESET_FAILED));
        },
      });
    }
  }

  gotoLogin() {
    this.store.dispatch(new SignOutWithoutRedirect()).subscribe(() => {
      this.store.dispatch([new Navigate(['/login'])]).subscribe(() => {
        window?.location.reload();
      });
    });
  }
}
