<div *ngIf="{ view: (view$ | async) } as asyncData" class="auth-content-wrapper z-fx-col z-fx-gap-48">
  <a href="//zef.fi" target="_blank">
    <img src="assets/images/zeffi-bird.svg" width="32" alt="ZEF Logo" i18n-alt="logo image alt" />
  </a>

  <div class="auth-content-container z-fx-col">
    <div class="z-fx-col z-fx-gap-16">
      <strong class="zef-text-h2" i18n>Reset password</strong>
      <span class="zef-text-p2-muted">{{ email || 'dev@sasxa.net' }}</span>
    </div>

    <div class="z-fx-col z-fx-gap-24">
      <ng-container *ngIf="asyncData.view === View.SUCCESS">
        <div class="z-fx z-fx-gap-16 z-fx-start-center">
          <mat-icon color="success" class="zef-icon-large">bullet_check</mat-icon>
          <strong class="zef-text-p1" i18n>Password updated</strong>
        </div>

        <button mat-raised-button class="zef-stretch-button" color="ink" (click)="gotoLogin()">
          <span i18n>Log in</span>
        </button>
      </ng-container>

      <ng-container *ngIf="asyncData.view !== View.SUCCESS">
        <auth-passwords #password="AuthPasswords"></auth-passwords>

        <button
          mat-raised-button
          class="zef-stretch-button"
          color="ink"
          [disabled]="asyncData.view === View.PROGRESS"
          [class.loading]="asyncData.view === View.PROGRESS"
          (click)="submitPassword(password.data)"
        >
          <span i18n>Update password</span>
        </button>
      </ng-container>
    </div>
  </div>
</div>
