import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthView } from '@auth/auth.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AuthManager } from '@shared/services/auth-manager.service';
import { DialogControl } from '@shared/services/dialog-control.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { PrefsState } from '@shared/states/prefs.state';
import { RouterState } from '@shared/states/router.state';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'auth-app',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LifecycleHooks],
})
export class AuthApp implements OnInit {
  public View = AuthView;

  @Select(RouterState.url)
  url$!: Observable<string>;

  @Select(PrefsState.isMobile)
  isMobile$!: Observable<boolean>;

  @Select(RouterState.queryParams)
  queryParams$!: Observable<Params>;

  view$: BehaviorSubject<AuthView | true | null> = new BehaviorSubject(null);
  backgroundImages$ = new BehaviorSubject([
    'assets/decos/bg-random-1.jpg',
    'assets/decos/bg-random-2.jpg',
    'assets/decos/bg-random-3.jpg',
    'assets/decos/bg-random-4.jpg',
    'assets/decos/bg-random-5.jpg',
    'assets/decos/bg-random-6.jpg',
    'assets/decos/bg-random-7.jpg',
    'assets/decos/bg-random-8.jpg',
    'assets/decos/bg-random-9.jpg',
  ]);

  email$ = this.queryParams$.pipe(
    filter((params) => Boolean(params?.oobCode)),
    switchMap((params) =>
      this.am.verifyEmailPasswordResetCode(params?.oobCode).pipe(catchError(() => this.invalidPasswordResetLink())),
    ),
  );

  constructor(
    private store: Store,
    private am: AuthManager,
    private dc: DialogControl,
    private route: ActivatedRoute,
    private lh: LifecycleHooks,
    @Optional() @Inject(MAT_DIALOG_DATA) readonly data: any,
  ) {}

  ngOnInit(): void {
    this.url$.pipe(takeUntil(this.lh.destroy), distinctUntilChanged()).subscribe((url) => {
      if (url.includes('resetPassword')) {
        this.view$.next(AuthView.RESET_PASSWORD);
      } else if (url.startsWith('/login/')) {
        this.view$.next(AuthView.SSO_DOMAIN_LOGIN);
      } else if (url.startsWith('/verify')) {
        this.view$.next(AuthView.INVALID_VERIFICATION);
      } else if (url.startsWith('/private')) {
        this.view$.next(AuthView.REPORT_AUTHENTICATION);
      } else if (url.startsWith('/signup/')) {
        const inviteKeyOrTeamHandle = this.store.selectSnapshot(RouterState.routeParam('inviteKey'));
        this.am.getSignupData(inviteKeyOrTeamHandle).subscribe((data) => {
          if (!data) {
            this.store.dispatch(new Navigate(['/signup']));
            this.am.signup$.next(null);
          } else {
            this.view$.next(true);
          }
        });
      } else if (url.startsWith('/login') || this.view$.value !== AuthView.SSO_DOMAIN_LOGIN) {
        // Other cases are handled in sidenav

        this.view$.next(true);
      }
    });

    this.am.signup$.pipe(takeUntil(this.lh.destroy)).subscribe((signup) => {
      if (signup?.imageUrl) {
        this.backgroundImages$.next([signup.imageUrl]);
      }
    });

    const ssoDomain = this.route.snapshot.params?.ssoDomain;

    if (ssoDomain) {
      if (ssoDomain === 'ory' || ssoDomain === 'ory-test' || ssoDomain === 'varma' || ssoDomain === 'varma-test') {
        this.am.oryLogin(ssoDomain);
      } else {
        this.am.ssoLogin(ssoDomain === 'test' ? '' : ssoDomain);
      }
    }

    if (this.data?.signup) {
      this.am.signup$.next(this.data.signup);
    }
  }

  hideDrawer(view: AuthView) {
    const hideDrawer =
      !view ||
      view === AuthView.RESET_PASSWORD ||
      view === AuthView.SSO_DOMAIN_LOGIN ||
      view === AuthView.INVALID_VERIFICATION;

    return hideDrawer;
  }

  @HostListener('window:beforeunload')
  private unload() {
    // super.cancelSignup();
  }

  private invalidPasswordResetLink() {
    this.dc
      .openErrorDialog(
        $localize`:dialog title@@zef-i18n-00078:Invalid Link`,
        $localize`:dialog content@@zef-i18n-00079:Could not proceed to password reset. Please check your link.`,
      )
      .afterClosed()
      .subscribe(() => {
        this.store.dispatch(new Navigate(['/login'])).subscribe(() => {
          window?.location.reload();
        });
      });

    return of(null);
  }
}
